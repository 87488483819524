<script setup>
import { useStore } from "vuex"
import router from "../router"

const store = useStore()

function OpenDivMenu() {
  const divMenu = document.getElementById('div-menu')
  const menuContent = document.getElementById('div-menu-content')

  setTimeout(() => {
    if (divMenu.classList.contains('opened'))
      menuContent.classList.remove('hidden')
  }, 150)

  divMenu.classList.toggle('opened')

  if (!divMenu.classList.contains('opened')) {
    divMenu.classList.add('closed')
  } else {
    divMenu.classList.remove('closed')
  }

  if (divMenu.classList.contains('closed'))
    menuContent.classList.add('hidden')
}

</script>


<template>
  <div id="div-menu">
    <i @click="OpenDivMenu()" class="fa fa-bars"></i>
    <div id="div-menu-content" class="hidden">
      <div :class="{ 'div-menu-white': router.currentRoute.value.name == 'Hub' }" @click="router.push('hub')">HOME</div>
      <div :class="{ 'div-menu-white': router.currentRoute.value.name == 'UsersList' }"
        v-if="store.getters.User.admin == 1" @click="router.push('userslist')">UTILIZADORES</div>
      <div :class="{ 'div-menu-white': router.currentRoute.value.name == 'Import' }"
        v-if="store.getters.User.admin == 1" @click="router.push('import')">TABELAS</div>
      <div :class="{ 'div-menu-white': router.currentRoute.value.name == 'Hierarchy' }"
        v-if="store.getters.User.admin == 1" @click="router.push('hierarchy')">HIERARQUIA</div>
      <!-- <div :class="{ 'div-menu-white': router.currentRoute.value.name == 'Teams' }" v-if="store.getters.User.admin == 1"
        @click="router.push('teams')">EQUIPAS</div> -->
      <div :class="{ 'div-menu-white': router.currentRoute.value.name == 'Settings' }"
        v-if="store.getters.User.admin == 1" @click="router.push('settings')">DEFINIÇÕES</div>
      <div :class="{ 'div-menu-white': router.currentRoute.value.name == 'Licensing' }"
        v-if="store.getters.User.admin == 1" @click="router.push('licensing')">LICENCIAMENTO</div>
      <div @click="router.push('logout')">SAIR</div>
      <div :class="{ 'div-menu-white help-selected-border-color': router.currentRoute.value.name == 'Help' }" v-if="store.getters.User.admin == 1"
        @click="router.push('help')" class="help">HELP</div>
    </div>
  </div>
</template>

<style scoped>
.div-menu-white {
  color: white !important;
}

#div-menu-content {
  margin-top: 20px;
}

.help {
  border: 2px solid rgba(24, 232, 255, 0.7) !important;
  border-right: none !important;
  border-left: none !important;
  color: rgba(24, 232, 255, 0.7);
}

.help:hover {
  border-color: white !important;
}

.help-selected-border-color {
  border-color: white !important;
}

#div-menu-content>div {
  cursor: pointer;
  font-weight: 600;
  padding: 7.5% 0% 7.5% 20%;
  border: 2px solid transparent;
  border-right: none;
  border-left: none;
}

#div-menu-content>div:hover {
  color: white;
}

.fa-bars {
  font-size: 2em;
  cursor: pointer;
  margin-left: 16px;
  transition: 200ms;
}

.opened>.fa-bars {
  font-size: 1.5em;
  margin-left: 16px;
}

#div-menu>i {
  color: white;
}

#div-menu {
  padding: 16px 0px 16px 0px;
  min-height: 100vh;
  min-width: max-content;
}

#div-menu.opened {
  animation-name: open-menu;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  background-color: #231f20 !important;
  color: #a976fd;
  min-width: 180px !important;
}

#div-menu.closed {
  animation-name: closed-menu;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
}

@keyframes open-menu {
  from {
    width: 0px;
  }

  to {
    width: 13vw;
    background-color: #00000033;
    color: var(--font-cirvalue-day);
    flex: none;
  }
}

@keyframes closed-menu {
  from {
    width: 13vw;
    background-color: #00000033;
    color: var(--font-cirvalue-day);
    flex: none;
  }

  to {
    width: 0;
  }
}
</style>
