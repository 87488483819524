<script setup>
import { HUB } from '@/hub'
import { Gems } from "@/gems"
import { ref } from "@vue/reactivity"

import ImportUploadFile from "../components/ImportUploadFile"
import Menu from '../components/Menu'
import { onMounted } from 'vue'

const multiFilesContainer = ref(null);
const fileWithError = ref(null);
const arrMultiFiles = ref([])
const arrImportedFiles = ref([])

async function Import(type) {

  const fileInput = document.getElementById(type)
  const file = document.getElementById(type).files[0]

  if (!file) {
    fileInput.click()
    return
  }

  let formData = new FormData()
  formData.append("csv", file)

  const response = await HUB.CallBackEnd({
    action: HUB.Action(`import-uploaded-files&type=${type}`),
    data: formData
  });

  document.getElementById(type).value = null

  if (response.data.error) {
    Gems.Telegram(response.data.message, "tele-not-allowed")

    if (response.data.excel)
      Gems.ExportExcel(response.data.data)
    return
  }

  GetFilesList()

  if (type == 'users') {
    const ws_res = await Gems.CallWS({ app: "kpi", action: "update-users-data" });
    if (ws_res?.data != true) {
      Gems.Telegram("Não foi possível gravar o histórico das equipas no CIRVALUE KPI", "tele-not-allowed");
      return
    }
  }

  Gems.Telegram(response.data.message, "tele-success")
}

async function Export(type) {
  const res = await HUB.CallBackEnd({
    action: HUB.Action(`export&type=${type}`)
  })

  if (res.error == 0) {
    Gems.Telegram('Certifique-se de que o seu browser não está a bloquear o download', 'tele-success');
    Gems.ExportExcel(res.data);
  }
}

function ConfirmDeleteFile(file) {
  Gems.Telegram(`Apagar ficheiro ${file.name}?`, "tele-info", "", "")
    .then(
      () => { DeleteFile(file.filename) },
      () => { }
    )
}

async function DeleteFile(filename) {
  const response = await HUB.CallBackEnd({
    action: HUB.Action(`delete-file`),
    data: {
      filename
    }
  })

  if (response.data.error)
    Gems.Telegram(response.data.message, "tele-not-allowed")
  else
    Gems.Telegram("Ficheiro removido", "tele-success")

  GetFilesList()
}

function OpenMultiFileSelect() {
  document.getElementById('multi-files').click()
}

async function GetFilesList() {
  const response = await HUB.CallBackEnd({ action: HUB.Action('get-multi-files-data') })

  arrMultiFiles.value = response.data.multi_files_list
  arrImportedFiles.value = response.data.imported_files_list
}

async function UploadFile(input, type = false, inputLabel = false) {
  const formData = new FormData()
  const btnImport = type ? document.getElementById(`btn-import-${type}`) : false;

  for (let i in input.files) {
    formData.append(i, input.files[i])
  }

  const typeParam = type ? `&type=${type}` : "";

  if (btnImport) {
    btnImport.classList.add('btn-disabled');
    btnImport.innerHTML = "AGUARDE...";
  } else {
    input.value = ""
  }

  const response = await HUB.CallBackEnd({
    action: HUB.Action(`upload-files${typeParam}`),
    data: formData
  })

  if (response.data.error == true) {
    if (btnImport) {
      btnImport.classList.remove('blink-import', 'btn-disabled')
      btnImport.innerHTML = `IMPORTAR <span class="arrow">&#8593;</span>`;
      inputLabel.placeholder = "Selecionar ficheiro [ CSV UTF-8 (Comma delimited) (*.csv) ]";
      input.value = ""
    }

    Gems.Telegram(response.data.message, "tele-not-allowed");
    return;
  }


  if (btnImport) {
    btnImport.classList.add('blink-import')
    btnImport.classList.remove('btn-disabled');
    btnImport.innerHTML = `IMPORTAR <span class="arrow">&#8593;</span>`;
  }

  GetFilesList();
}

function ConfirmImportUploadedFiles() {
  Gems.Telegram("Iniciar o processo de carregamento?", "tele-info", "", "")
    .then(
      () => { ImportUploadedFiles() },
      () => { }
    );
}

async function ImportUploadedFiles() {
  const response = await HUB.CallBackEnd({ action: HUB.Action('import-uploaded-files') })

  if (response.data.error) {
    Gems.Telegram(response.data.message, "tele-not-allowed")

    if (response.data.excel)
      Gems.ExportExcel(response.data.data)

    GetFilesList()
    fileWithError.value = response.data.file ?? null
    return
  }


  GetFilesList()
  Gems.Telegram("Processo concluído", "tele-success")
}

function DeleteFileHover(id) {
  const divRow = document.getElementById(id)

  divRow.classList.toggle('div-included-file-hover')
}

function ConvertToTimeZone(datetime) {
  const utcDate = new Date(datetime)
  const offsetInMinutes = utcDate.getTimezoneOffset()
  const localDate = new Date(utcDate.getTime() - offsetInMinutes * 60 * 1000)

  const pad = (num) => String(num).padStart(2, '0')

  // Extract components
  const year = localDate.getFullYear()
  const month = pad(localDate.getMonth() + 1) // Months are zero-based
  const day = pad(localDate.getDate())
  const hours = pad(localDate.getHours())
  const minutes = pad(localDate.getMinutes())
  const seconds = pad(localDate.getSeconds())

  const timeZoneInMinutes = new Date().getTimezoneOffset()
  const offsetHours = Math.floor(Math.abs(timeZoneInMinutes) / 60)
  const offsetMinutes = Math.abs(timeZoneInMinutes) % 60
  const timezoneSign = timeZoneInMinutes <= 0 ? "+" : "-"
  const gmtOffset = `GMT${timezoneSign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`
  
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${gmtOffset}`
}

function ShowImportInstructions(id) {
  const divImportInstructions = document.getElementById(id)
  let text

  if (id == 1)
    text = `<li>Dado nulo (vazio) em campos binários [INATIVO] será interpretado como "0" (zero).</li>
              <li>Os dados NÃO deverão conter o caracter ";" (ponto e vírgula).</li></i>
              <br/>
              <li>Novo registo:</li>
              <ul>
                <li>[coluna A] precisa estar vazia.</li>
                <li>O valor da [coluna B] não existe na Base de Dados.</li>
              </ul>
              <li>Atualização [colunas B, C, D e E]:</li>
              <ul>
                <li>[coluna A]  vazia e valor da [coluna B] existe na Base de Dados > Atualiza C, D e E.</li>
                <li>[coluna A]  não vazia > Atualiza B, C, D e E.</li>
              </ul>
              <br>`

  if (id == 2)
    text = `<li>Os dados NÃO deverão conter o caracter ";" (ponto e vírgula).</li>
          <br/>
          <li>ATIVO (GLOBAL) [coluna J]:</li>
          <ul>
            <li>Valor nulo (vazio) será ignorado.</li>
            <li>Valor "0" ou "1" atualizará o campo [ATIVO] em todas as aplicações.</li>
            <li>Valor nulo (vazio) nos restantes campos binários, será interpretado como "0" (zero).</li>
          </ul>
          <br/>
          <li>Novo registo:</li>
          <ul>
            <li>[coluna A] precisa estar vazia.</li>
            <li>O valor da [coluna B] não existe na Base de Dados.</li>
          </ul>
          <li>Atualização [colunas B em diante]:</li>
          <ul>
            <li>[coluna A]  vazia e valor da [coluna B] existe na Base de Dados > Atualiza C em diante.</li>
            <li>[coluna A]  não vazia > Atualiza B em diante</li>
          </ul>
          <br>`

  if (id == 3)
    text = `<li>Os dados NÃO deverão conter o caracter ";" (ponto e vírgula).</li>
            <li>A tabela inteira será substituída. Todos os registos que não existirem no ficheiro carregado serão perdidos.</li>`

  if (divImportInstructions.children.length > 1) {
    window.CloseImportInstructions(id);
  } else {
    const div = document.createElement("div");
    divImportInstructions.appendChild(div);

    div.innerHTML += `<button class='fas fa-times btn-close-import-instructions-modal' onclick='CloseImportInstructions(${id})'></button>`;

    if (id == 4) {
      div.innerHTML += `<li>Consulte as regras de importação individual de cada tabela.</li>
                        <li>Nome do ficheiro a importar: [palavra chave da tabela]. Ex.: "EQUIPAS".</li>
                        <li>Na coluna [KEY], só o cabeçalho deverá estar preenchido.</li>`
    } else {
      div.innerHTML += '<li>Apenas importe ficheiros que foram exportados deste interface.</li>'
      div.innerHTML += '<li>Não apague colunas do EXCEL exportado.</li>'
      div.innerHTML += '<li>Os registos serão processados pela ordem em que aparecem no ficheiro.</li>'
      div.innerHTML += text;
      div.innerHTML += '<li>Grave o ficheiro com o seguinte formato:</li><br/>'
      div.innerHTML += "<div class='div-excel-img'>";
    }

    div.setAttribute("class", "div-import-instructions");
  }
}

window.CloseImportInstructions = (id) => {
  document
    .getElementById(id)
    .removeChild(document.getElementById(id).lastElementChild);

  multiFilesContainer.value.classList.remove('div-last-import-instructions-opened')
}

async function DownloadFile(file) {
  let fileName = file.name;

  fetch(HUB.Action(`download-file&filename=${file.filename}`))
    .then((resp) => {
      try {
        const fileData = resp.blob();
        return fileData;
      } catch (error) {
        const errorRes = resp.json();

        if (errorRes[3]) {
          Gems.Telegram(errorRes[2], "tele-not-allowed");
          return;
        }
      }
    })
    .then((file) => {
      const url = window.URL.createObjectURL(file);
      const a = document.createElement("a");
      a.className = "hidden";
      a.href = url;
      a.download = decodeURI(fileName + '.csv');
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      Gems.Telegram(
        "Certifique-se de que o seu browser não está a bloquear o download",
        "tele-success"
      );
    })
    .catch(() =>
      Gems.Telegram(
        "Não foi possível localizar o ficheiro",
        "tele-not-allowed"
      )
    );
}

onMounted(() => {
  GetFilesList()
})
</script>


<template>
  <div id="div-container" class="main-container-background">

    <Menu></Menu>


    <div id="div-page-container">

      <img class="img-inpeople-default" src="../images/inpeople.png">

      <div class="div-container-info-circle">
        <div class="div-info-circle bg-field-unique"></div>
        <p class="p-info-text">
          Campos de dados únicos
        </p>
      </div>

      <div class="div-container-info-circle">
        <div class="div-info-circle bg-field-not-modify"></div>
        <p class="p-info-text">
          Em nenhuma circunstância modifique a coluna 'Key' [coluna A]
        </p>
      </div>

      <div>
        <div id="1" class="div-import-instructions-closed">
          <div class="div-icon-container">
            <i class="fas fa-info-circle icon" @click="
              ShowImportInstructions(1)
              ">
            </i>
            <span class="span-rule-text">Regras para importação</span>
          </div>
        </div>

        <div class="div-half-size-table">
          <div class="inputs-border div-btn-export hover-inputs-border">
            <button @click="Export('gender')" class="button clickable">
              <span>EXPORTAR "GÉNEROS"</span><span class="i-arrow">&#8595;</span>
            </button>
          </div>

          <table class="tbl-example">
            <thead>
              <tr>
                <th class="bg-field-not-modify">A. Key</th>
                <th>B. Código do género</th>
                <th>C. Descritor</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: 1234</td>
                <td>Ex.: A001</td>
                <td>Ex.: Feminino</td>
              </tr>
              <tr>
                <td>(campo numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
              </tr>
            </tbody>
          </table>
          <div class="div-upload-file">
            <ImportUploadFile :tableName="'gender'"
              @upload-file="UploadFile($event.input, $event.type, $event.inputLabel)" @import="Import($event)" />
          </div>
        </div>

        <div class="div-half-size-table">
          <div class="inputs-border div-btn-export hover-inputs-border">
            <button @click="Export('teams')" class="button clickable">
              <span>EXPORTAR "EQUIPAS"</span><span class="i-arrow">&#8595;</span>
            </button>
          </div>

          <table class="tbl-example">
            <thead>
              <tr>
                <th class="bg-field-not-modify">A. Key</th>
                <th>B. Código da equipa</th>
                <th>C. Descritor</th>
                <th>D. Inativo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: 1234</td>
                <td>Ex.: A001</td>
                <td>Ex.: Coordenação</td>
                <td>Ex.: 1</td>
              </tr>
              <tr>
                <td>(campo numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo binário - 0 ou 1)</td>
              </tr>
            </tbody>
          </table>
          <div class="div-upload-file">
            <ImportUploadFile :tableName="'teams'"
              @upload-file="UploadFile($event.input, $event.type, $event.inputLabel)" @import="Import($event)" />
          </div>
        </div>
        <div class="div-half-size-table">
          <div class="inputs-border div-btn-export hover-inputs-border">
            <button @click="Export('categories')" class="button clickable">
              <span>EXPORTAR "CATEGORIAS"</span><span class="i-arrow">&#8595;</span>
            </button>
          </div>

          <table class="tbl-example">
            <thead>
              <tr>
                <th class="bg-field-not-modify">A. Key</th>
                <th>B. Código da categoria</th>
                <th>C. Descritor</th>
                <th>D. Inativo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: 1234</td>
                <td>Ex.: A001</td>
                <td>Ex.: Diretoria</td>
                <td>Ex.: 0</td>
              </tr>
              <tr>
                <td>(campo numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo binário - 0 ou 1)</td>
              </tr>
            </tbody>
          </table>
          <div class="div-upload-file">
            <ImportUploadFile :tableName="'categories'"
              @upload-file="UploadFile($event.input, $event.type, $event.inputLabel)" @import="Import($event)" />
          </div>
        </div>
        <div class="div-half-size-table">
          <div class="inputs-border div-btn-export hover-inputs-border">
            <button @click="Export('jobs')" class="button clickable">
              <span>EXPORTAR "FUNÇÕES"</span><span class="i-arrow">&#8595;</span>
            </button>
          </div>

          <table class="tbl-example">
            <thead>
              <tr>
                <th class="bg-field-not-modify">A. Key</th>
                <th>B. Código da função</th>
                <th>C. Descritor</th>
                <th>D. Valor/Hora</th>
                <th>E. Inativo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: 1234</td>
                <td>Ex.: A001</td>
                <td>Ex.: CEO</td>
                <td>Ex.: 20.50</td>
                <td>Ex.: 1</td>
              </tr>
              <tr>
                <td>(campo numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo numérico)</td>
                <td>(campo binário - 0 ou 1)</td>
              </tr>
            </tbody>
          </table>
          <div class="div-upload-file">
            <ImportUploadFile :tableName="'jobs'"
              @upload-file="UploadFile($event.input, $event.type, $event.inputLabel)" @import="Import($event)" />
          </div>
        </div>
      </div>

      <div>
        <div id="2" class="div-import-instructions-closed">
          <div class="div-icon-container">
            <i class="fas fa-info-circle icon" @click="
              ShowImportInstructions(2)
              ">
            </i>
            <span class="span-rule-text">Regras para importação</span>
          </div>
        </div>

        <div>
          <div class="inputs-border div-btn-export hover-inputs-border">
            <button @click="Export('users')" class="button clickable">
              <span>EXPORTAR "UTILIZADORES"</span><span class="i-arrow">&#8595;</span>
            </button>
          </div>

          <table class="tbl-example tbl-example-users">
            <thead>
              <tr>
                <th class="bg-field-not-modify">A. Key</th>
                <th class="bg-field-unique">B. N° Interno</th>
                <th>C. Nome completo</th>
                <th>D. Nome profissional</th>
                <th>E. E-Mail</th>
                <th class="bg-field-unique">F. Username</th>
                <th>G. Género<br>[Código]</th>
                <th>H. Categoria<br>[Código]</th>
                <th>I. Função<br>[Código]</th>
                <th>J. Ativo<br>(Global)</th>
                <th class="td-fade-out-first">K. Ativo<br>(App 1)</th>
                <th class="td-fade-out-second">L. Admin<br>(App 1)</th>
                <th class="td-fade-out-third">M. Ativo<br>(App 2)</th>
                <th class="td-fade-out-fourth">N. Admin<br>(App 2)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: 1234</td>
                <td>Ex.: A001</td>
                <td>Ex.: Manuel da Silva Costa</td>
                <td>Ex.: Manuel Silva</td>
                <td>Ex.: manuel.silva@mail.pt</td>
                <td>Ex.: Silva.manuel</td>
                <td>Ex.: 10A0</td>
                <td>Ex.: 200B</td>
                <td>Ex.: 3D00</td>
                <td class="td-fade-out-first">Ex.: 1</td>
                <td class="td-fade-out-second">Ex.: 1</td>
                <td class="td-fade-out-third">Ex.: 0</td>
                <td class="td-fade-out-fourth">Ex.: 0</td>
              </tr>
              <tr>
                <td>(campo numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo binário - 0 ou 1)</td>
                <td class="td-fade-out-first">(campo binário - 0 ou 1)</td>
                <td class="td-fade-out-second">(campo binário - 0 ou 1)</td>
                <td class="td-fade-out-third">(campo binário - 0 ou 1)</td>
                <td class="td-fade-out-fourth">(campo binário - 0 ou 1)</td>
              </tr>
            </tbody>
          </table>
          <div class="div-upload-file">
            <ImportUploadFile :tableName="'users'"
              @upload-file="UploadFile($event.input, $event.type, $event.inputLabel)" @import="Import($event)" />
          </div>
        </div>
      </div>

      <div class="div-half-size-table">
        <div id="3" class="div-import-instructions-closed">
          <div class="div-icon-container">
            <i class="fas fa-info-circle icon" @click="ShowImportInstructions(3)"></i>
            <span class="span-rule-text">Regras para importação</span>
          </div>
        </div>

        <div class="inputs-border div-btn-export hover-inputs-border">
          <button @click="Export('hierarchy')" class="button clickable">
            <span>EXPORTAR "HIERARQUIA"</span><span class="i-arrow">&#8595;</span>
          </button>
        </div>

        <table class="tbl-example">
          <thead>
            <tr>
              <th>A. N° interno da chefia</th>
              <th>B. N° interno do subordinado</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ex.: A001</td>
              <td>Ex.: A020</td>
            </tr>
            <tr>
              <td>(campo alfa-numérico)</td>
              <td>(campo alfa-numérico)</td>
            </tr>
          </tbody>
        </table>
        <div class="div-upload-file">
          <ImportUploadFile :tableName="'hierarchy'"
            @upload-file="UploadFile($event.input, $event.type, $event.inputLabel)" @import="Import($event)" />
        </div>
      </div>

      <div class="div-half-size-table">
        <div class="inputs-border div-btn-export hover-inputs-border">
          <button @click="Export('teams-formation')" class="button clickable">
            <span>EXPORTAR "CONSTITUIÇÃO" DE EQUIPAS</span><span class="i-arrow">&#8595;</span>
          </button>
        </div>

        <table class="tbl-example">
          <thead>
            <tr>
              <th>A. Código da equipa</th>
              <th>B. Nº Interno</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ex.: A001</td>
              <td>Ex.: 0928213</td>
            </tr>
            <tr>
              <td>(campo alfa-numérico)</td>
              <td>(campo alfa-numérico)</td>
            </tr>
          </tbody>
        </table>
        <div class="div-upload-file">
          <ImportUploadFile :tableName="'teams_formation'"
            @upload-file="UploadFile($event.input, $event.type, $event.inputLabel)" @import="Import($event)" />
        </div>
      </div>

      <div>
        <div id="4" class="div-import-instructions-closed">
          <div class="div-icon-container">
            <i class="fas fa-info-circle icon" @click="
              multiFilesContainer.classList.toggle('div-last-import-instructions-opened')
            ShowImportInstructions(4);
            "></i>
            <span class="span-rule-text">Regras para importação</span>
          </div>
        </div>

        <div class="div-multi-file-container" ref="multiFilesContainer">

          <div>
            <div class="inputs-border div-btn-export hover-inputs-border">
              <div>
                <button @click="OpenMultiFileSelect()" class="button clickable">
                  INCLUIR FICHEIROS
                </button>
                <input class="hidden-input" id="multi-files" type="file" accept=".csv"
                  @change="UploadFile($event.target)" multiple />
              </div>
            </div>

            <div class="div-multi-file scrollbar">
              <div v-for="(file, index) in arrMultiFiles" :key="index">
                <div class="div-included-file-row" :class="{ 'span-filename-error': fileWithError == file.filename }"
                  :id="`included-file-${index}`">
                  <i class="fas fa-file" aria-hidden="true"></i>

                  <span class="span-filename line-clamp line-clamp-1"
                    @mouseenter="Gems.Tooltip($event.target, 'Download', 'tooltip'), DeleteFileHover(`included-file-${index}`)"
                    @mouseleave="DeleteFileHover(`included-file-${index}`)" @click="DownloadFile(file)">{{ file.name
                    }}</span>

                  <i class="fas fa-trash clickable" @mouseover="DeleteFileHover(`included-file-${index}`)"
                    @mouseleave="DeleteFileHover(`included-file-${index}`)"
                    @mouseenter="Gems.Tooltip($event.target, 'Apagar Ficheiro', 'tooltip')"
                    @click="ConfirmDeleteFile(file)" aria-hidden="true"></i>
                </div>
              </div>
            </div>

            <div class="inputs-border div-btn-export hover-inputs-border">
              <button @click="ConfirmImportUploadedFiles()" class="button clickable">
                INICIAR PROCESSO
              </button>
            </div>
          </div>

          <div class="div-multi-file-imported scrollbar">
            <div class="div-header-row">
              <span>Ficheiro</span>
              <span>Data da importação </span>
            </div>

            <div class="div-row" v-for="(file, index) in arrImportedFiles" :key="index">
              <span class="line-clamp line-clamp-1" @mouseover="Gems.TooltipIfNeeded($event.target)">{{ file.name }}</span>
              <span class="line-clamp line-clamp-1" @mouseover="Gems.TooltipIfNeeded($event.target)">{{ ConvertToTimeZone(file.date) }}</span>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<style scoped>
#div-container {
  display: flex;
  font-family: "Space Grotesk", sans-serif;
  overflow: auto;
}

#div-page-container {
  padding: 25px 2%;
}

.div-container-info-circle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.div-info-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.p-info-text {
  font-size: .9em;
  margin: 0px 5px;
  font-weight: 300;
}

.div-upload-file {
  display: flex;
  margin-bottom: 5px;
  margin-top: 5px;
  align-items: center;
}

.tbl-example {
  width: 100%;
  height: 100px;
  text-align: center;
  word-wrap: break-word;
  border-collapse: separate;
  border: none;
  border-spacing: 0;
  background: #231f20;
  border-radius: 7px;
}

.tbl-example thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-collapse: separate;
}


.tbl-example-users {
  border: none;
  position: relative;
}

.tbl-example-users::before {
  border-radius: 7px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mask-image: linear-gradient(270deg, #231f20, rgba(0, 0, 0, 0) 3%);
  -webkit-mask-image: linear-gradient(270deg, #231f20, rgba(0, 0, 0, 0) 3%);
  border: none;
  background-color: black;
  z-index: 1;
}

.tbl-example thead tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.tbl-example thead:first-child tr:first-child th:first-child,
.tbl-example tbody:first-child tr:first-child td:first-child {
  border-radius: 7px 0 0 0;
}

.tbl-example thead:last-child tr:last-child th:first-child,
.tbl-example tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 7px;
}

.tbl-example th,
.tbl-example tbody td {
  text-align: left;
  vertical-align: top;
}

.tbl-example tbody td {
  border-top: 1px solid black;
}

.tbl-example tbody td:not(:first-child) {
  border-left: 1px solid black;
}

.div-half-size-table {
  width: 50% !important;
  margin-bottom: 20px;
}

.tbl-example td,
.tbl-example th {
  height: 30px;
}

.tbl-example thead th,
.tbl-example tbody td {
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
}

.tbl-example thead th {
  font-weight: bold;
  overflow: hidden;
  text-transform: uppercase;
}

.tbl-example thead th:not(:first-child) {
  border-left: 1px solid black;
}

.div-btn-export {
  margin-bottom: 5px;
}

.inputs-border div-btn-export:not(:first-of-type) {
  margin-top: 30px;
}

.inputs-border div-btn-export:first-of-type {
  margin-top: 10px;
}

.div-import-instructions-closed {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  margin-bottom: 5px;
}

.div-icon-container {
  display: flex;
  align-items: center;
}

.div-icon-container>span {
  font-weight: bold;
}

.icon {
  font-size: 1em;
  cursor: pointer;
  margin-right: 5px;
}

.bg-field-not-modify {
  background-color: #ff0050;
}

.bg-field-unique {
  background-color: rgba(24, 232, 255, 0.7);
}


.div-multi-file-container {
  display: flex;
  height: 396px;
  gap: 20px;
}

.div-multi-file-container div {
  flex: 1;
}

.div-multi-file,
.div-multi-file-imported {
  background: #231f20;
  border-radius: 7px;
  overflow: auto;
}

.div-multi-file-imported {
  overflow-y: scroll;
  font-size: 12px;
}

.div-multi-file {
  height: 300px;
  box-shadow: inset 0 0 5px 0 #000000;
  margin-bottom: 5px;
  padding: 10px 20px;
}

.div-multi-file div,
.div-multi-file-imported div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.div-multi-file-imported div {
  padding: 5px;
}

.div-included-file-row {
  padding: 8px 0px;
}

.span-filename {
  width: -webkit-fill-available;
  padding-left: 10px;
  cursor: pointer;
}

.fa-trash:hover,
.span-filename-error {
  color: #ff0050;
}

.div-header-row {
  font-weight: bold;
  border-bottom: 1px solid black;
  padding: 0 !important;
  text-transform: uppercase;
}

.div-header-row span:nth-child(2) {
  border-left: 1px solid black;
}

.div-row,
.div-header-row span {
  padding: 5px;
  cursor: default;
}

.div-header-row,
.div-row {
  display: flex;
  text-align: center;
}

.div-header-row span:nth-child(1),
.div-row span:nth-child(1),
.div-header-row span:nth-child(2),
.div-row span:nth-child(2) {
  width: 50%;
}

.div-included-file-hover {
  color: var(--default-hover-color);
}

.btn-disabled {
  color: var(--default-gray-color);
  pointer-events: none;
}

.hover-inputs-border:has(.btn-disabled):hover {
  background: var(--input-gradient-border) !important;
}
</style>

<style>
.blink-import {
  animation: blink-import 1s step-start infinite;
}

@keyframes blink-import {
  50% {
    background: #00000040;
    color: black !important;
  }

  100% {
    background: black;
    color: var(--medium-gray-color) !important;
  }
}


.div-import-instructions {
  background-color: #8000bbbb;
  text-align: initial;
  box-shadow: 0 0 10px 10px #ffffff11;
  max-height: 720px !important;
  min-height: 20px;
  width: 631px;
  min-width: 290px;
  cursor: default;
  font-size: .8em;
  font-weight: 300;

  backdrop-filter: blur(10px);
  position: absolute;
  left: 30px;
  top: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 10;
}

.div-import-instructions li {
  width: 95%;
}

.btn-close-import-instructions-modal {
  position: absolute !important;
  top: 4px;
  right: 15px;
  font-size: 1.3em !important;
  cursor: pointer;
  border: none !important;
  width: 10px !important;
  height: 10px !important;
  background-color: transparent;
}

.btn-close-import-instructions-modal:hover {
  color: var(--default-hover-color) !important;
  position: absolute;
  background-color: transparent !important;
}

.div-excel-img {
  display: block;
  height: 380px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../images/excel.png");
}

.span-rule-text {
  font-size: .8em;
}

.td-fade-out-first {
  -webkit-mask: linear-gradient(90deg, #000, #0009);
  mask: linear-gradient(90deg, #000, #0009);
}

.td-fade-out-second {
  -webkit-mask: linear-gradient(90deg, #0009, #0007);
  mask: linear-gradient(90deg, #0009, #0007);
}

.td-fade-out-third {
  -webkit-mask: linear-gradient(90deg, #0006, #0004);
  mask: linear-gradient(90deg, #0006, #0004);
}

.td-fade-out-fourth {
  -webkit-mask: linear-gradient(90deg, #0003, #0001);
  mask: linear-gradient(90deg, #0003, #0001);
}

.i-arrow {
  margin-left: 5px;
  font-size: 1em;
}

.div-last-import-instructions-opened {
  margin-bottom: 250px !important;
}

</style>
